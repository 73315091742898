@import "./var";

.iconGen(@icon,@width:10px,@height:10px){
  background: url("@{icon_url}@{icon}.png") no-repeat center;
  width: @width;
  height: @height;
  background-origin:content-box;
  background-size: contain;
  display: inline-block;
}

.icon-index-swiper-left{
  .iconGen(icon-index-swiper-left,60px,60px);
}

.icon-partner-swiper-left{
  .iconGen(icon-partner-swiper-left,60px,60px);
}

.icon-city{
  .iconGen(icon-city,18px,23px);
}
.icon-email{
  .iconGen(icon-email,20px,16px);
}
.icon-tel{
  .iconGen(icon-tel,20px,19px);
}
.icon-wx{
  .iconGen(icon-wx,22px,18px);
}
.icon-weibo{
  .iconGen(icon-weibo,22px,18px);
}
.icon-b-left{
  .iconGen(icon-b-left,8px,14px);
}
.icon-b-right{
  .iconGen(icon-b-right,8px,14px);
}
.icon-search{
  .iconGen(icon-search,20px,20px);
}
