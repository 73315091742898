@import "./var";
@import "./common";
@import "./icon";
@import "./reset.less";


body,html{
  height: 100%;
  min-height: 100%;
  min-width: 1180px;
}
body{
  padding-top: 90px;
}

#header{
  z-index: 999;
  height: 90px;
  box-shadow: 0px 2px 12px 0px 
		rgba(9, 9, 9, 0.12);
  background-color: @white;
  min-width: 1200px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  &:after{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: calc((100% - 1180px) / 2);
    background-color: @green;
  }
  .logo{
    float: left;
    line-height: 90px;
    img{
      display: inline-block;
    }
  }
  .right-menu{
    float: right;
    height: 90px;
    position: relative;
    z-index: 10;
    background-color: @green;
    &:after{
      content: '';
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      width: 50px;
      height: 90px;
      background-color: @white;
    }
    .menu-list{
      position: relative;
      z-index: 3;
      background-color: @green;
      transform: skew(-25deg, 0);
      li{
        float: left;
        transition: all .3s;
        position: relative;
        > .sub-menu{
          display: none;
          font-size: 14px;
          color: @black;
          transform: skew(25deg, 0);
          position: absolute;
          background-color: #fff;
          padding: 18px 30px;
          left: 0%;
          top: 90px;
          box-shadow: 0 3px 16px 0 rgba(2, 20, 40, 0.18);
          > .item{
            padding-bottom: 22px;
            display: block;
            white-space: nowrap;
            &:last-child{
              padding-bottom: 0;
            }
            &:hover,&.active{
              color: @green;
            }
          }
        }
        &.active, &:hover{
          background-color: @yellow;
        }
        &:hover{
          > .sub-menu{
            display: block;
          }
        }
        > a{
          color: @white;
          line-height: 90px;
          height: 90px;
          font-size: 18px;
          display: block;
          padding: 0 18px;
          transform: skew(25deg, 0);
        }
      }
    }
  }
}

#footer{
  margin-top: 100px;
  .footer-bg{
    width: 100%;
    img{
      width: 100%;
      height: auto;
    }
  }
  .wrapper{
    background-color: @green;
    color: @white;
    padding: 60px 0 0;
    font-size: 14px;
    line-height: 2.5;
  }
  .list{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 40px;
  }
  .footer-menu-list{
    display: flex;
    flex-wrap: wrap;
    border-right: 1px solid #79c34c;
    width: 260px;
    flex-shrink: 1;
    li{
      width: 50%;
    }
  }
  .footer-qrcode-list{
    display: flex;
    justify-content: flex-end;
    border-left: 1px solid #79c34c;
    width: 340px;
    flex-shrink: 1;
    .item{
      text-align: center;
      margin-left: 30px;
      width: 100px;
      height: 100px;
      > img{
        width: 100%;
        height: 100%;
      }
      p{
        margin-top: 10px;
      }
    }
  }
  .footer-info{
    height: 70px;
    line-height: 70px;
    color: @white;
    font-size: 14px;
    border-top: 1px solid #79c34c;
    background-color: @green;
    .container{
      display: flex;
      justify-content: center;
      div{
        margin: 0 20px;
      }
    }
  }
}

.page-index{
  .swiper-area{
    position: relative;
    .swiper-cover-mask{
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
      width: 100%;
    }
    .btn-swiper-button-prev,.btn-swiper-button-next{
      position: absolute;
      top: 50%;
      margin-top: -45px;
      z-index: 3;
      cursor: pointer;
      border-radius: 25px;
    }
    .btn-swiper-button-prev{
      left: 120px;
    }
    .btn-swiper-button-next{
      right: 120px;
      transform: rotateY(180deg);
    }
    #slogan{
      position: absolute;
      width: 40%;
      bottom: -35px;
      left: 10%;
      z-index: 100;
      img{
        max-width: 100%;
        height: auto;
        display: block;
      }
    }
  }
  .index-swiper{
    overflow: hidden;
    position: relative;
    width: 100%;
    
    .swiper-slide{
      a{
        display: block;
        height: 100%;
        overflow: hidden;
        img{
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .stat-list{
    margin-top: 80px;
    .list{
      display: flex;
      justify-content: space-between;
    }
    .item{
      text-align: center;
      .stat{
        color: @green;
        margin-top: 10px;
        .number{
          font-size: 30px;
        }
        .unit{
          margin-left: 5px;
        }
      }
      .desc{
        color: @light-black;
        margin-top: 5px;
        font-size: 14px;
      }
    }
    .tips{
      color: @gray;
      font-size: 14px;
      text-align: right;
      margin-top: 20px;
    }
  }
  .donate-stat{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    box-shadow: 0 3px 16px 0 rgba(2, 20, 40, 0.18);
    padding: 0 20px;
    margin-top: 40px;
    border-radius: 3px;
    &:after{
      content: '';
      position: absolute;
      left: -10px;
      top: -25px;
      width: 61px;
      height: 42px;
      background-image: url("../../../../img/heart.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    .donate-swiper{
      font-size: 16px;
      color: @black;
      height: 40px;
      padding-right: 30px;
      margin-right: 30px;
      border-right: 1px solid @border;
    }
    .donate-sum-swiper{
      flex-grow: 1;
      .swiper-slide{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .donate-count{
          padding-right: 10px;
          color: @red;
          font-size: 26px;
          // max-width: 200px;
        }
        .donator{
          flex: 1;
          padding-right: 20px;
        }
        .r{
          font-size: 16px;
          color: #333333;
          display: flex;
          align-items: center;
        }
        .unit{
          color: @gray;
        }
      }
    }
    .donate-info-swiper{
      width: 440px;
      flex-shrink: 0;
      .swiper-slide{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .donator{
          max-width: 170px;
          padding-right: 10px;
        }
        .r{
          font-size: 16px;
          color: #333333;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .donate-count{
          padding-right: 10px;
          span{
            color: @red;
            padding: 0 3px;
          }
        }
      }
    }
    .donate-btn{
      flex-shrink: 0;
      a{
        display: block;
        border-radius: 5px;
        background-color: @green;
        color: @white;
        height: 40px;
        line-height: 40px;
        padding: 0 40px;
        font-size: 16px;
      }
    }
  }
  .index-project{
    margin-top: 70px;
    position: relative;
    padding: 55px 0;
    width: 100%;
    overflow: hidden;
    &:before{
      content: ' ';
      width: 100%;
      height: 55px;
      background-image: url('../../../../img/wave01.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:after{
      content: ' ';
      width: 100%;
      height: 55px;
      background-image: url('../../../../img/wave02.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .wrapper{
      background-color: #ecf6e9;
      position: relative;
      &:after{
        content: '';
        left: 0;
        bottom: 25px;
        width: 100%;
        height: 200px;
        background-image: url('../../../../img/plane.png');
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }
    .panel-title{
      padding-top: 50px;
      position: relative;
      z-index: 2;
    }
    .list{
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
      padding-bottom: 50px;
      position: relative;
      z-index: 2;
      &:before{
        content: '';
        position: absolute;
        left: -30px;
        top: -30px;
        width: 74px;
        height: 63px;
        background-image: url('../../../../img/cloud01.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        z-index: -1;
      }
      &:after{
        content: '';
        position: absolute;
        right: -220px;
        top: -100px;
        width: 200px;
        height: 150px;
        background-image: url('../../../../img/cloud02.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        z-index: -1;
      }
      .item{
        width: 372px;
        position: relative;
        box-shadow: 0 3px 16px 0 rgba(2, 20, 40, 0.18);
        &:hover{
          .desc{
            opacity: 1;
          }
        }
        .title{
          text-align: center;
          background-color: @white;
          height: 70px;
          line-height: 70px;
          font-size: 18px;
          color: #1a1a1a;
        }
        .desc{
          padding: 0 40px;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: @yellow;
          color: @white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transition: all .3s;
          .desc-title{
            font-size: 20px;
            position: relative;
          }
          .desc-detail{
            text-align: center;
            margin-top: 30px;
            font-size: 14px;
          }
          .more{
            margin-top: 20px;
          }
        }
      }
    }
  }

  .index-news{
    margin-top: 70px;
    .list{
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
      .item{
        width: 272px;
        box-shadow: 0 3px 16px 0 rgba(2, 20, 40, 0.18);
        .content{
          padding: 15px;
        }
        .title{
          font-size: 18px;
        }
        .date{
          font-size: 14px;
          color: @gray;
          margin-top: 10px;
        }
        .desc{
          font-size: 14px;
          color: @light-black;
          margin-top: 10px;
        }
        &:not(:nth-child(4n)){
          margin-right: calc(92px / 3);
        }
      }
    }
  }

  .index-partner{
    margin-top: 100px;
    .list{
      padding: 0 60px;
      position: relative;
    }
    .btn-swiper-button-prev,.btn-swiper-button-next{
      position: absolute;
      top: 50%;
      margin-top: -30px;
      z-index: 2;
      cursor: pointer;
      border-radius: 25px;
    }
    .btn-swiper-button-prev{
      left: -20px;
    }
    .btn-swiper-button-next{
      right: -20px;
      transform: rotateY(180deg);
    }
    .list{
      margin-top: 40px;
      a{
        display: flex;
        height: 110px;
        margin: 3px;
        align-items: center;
        justify-content: center;
        padding: 10px;
        transition: all .3s;
        &:hover{
          box-shadow: 0 3px 16px 0 rgba(2, 20, 40, 0.18);
        }
      }
    }
  }
  
} 
.introduce-img-view{
  position: relative;
  height: 500px;
  margin-top: -100px;
  .flex;
  .align-center;
  .bg-line{
    width: 1140px;
  }
  > .img {
    position: absolute;
    z-index: 2;
    border-radius: 50%;

  }
  .l-1{
    width: 304px;
    left: -10px;
    top: 100px;
  }
  .l-2{
    width: 248px;
    right: 530px;
    top: 110px;
  }
  .l-3{
    width: 260px;
    right: 180px;
    top: 180px;
  }
  .l-4{
    width: 130px;
    right: 0;
    top: 105px;
  }
}
.introduce-group{
  margin-bottom: 90px;
  > .title{
     position: relative;
     display: inline-block;
     font-size: 28px;
     font-weight: bold;
     color: @black;
     margin-bottom: 30px;
     &:after{
       position: absolute;
       content: '';
       display: inline-block;
       .setBg('min-sun.png',41px,38px);
       top: -31px;
      right: -40px;
     }
  }
  &:last-child{
    margin-bottom: 0;
  }
}
.org-relation-view{
  width: 949px;
  margin: 0 auto;
	background-color: #ffffff;
	box-shadow: 0px 2px 20px 0px 
    rgba(9, 9, 9, 0.24);
  padding: 46px 0;
  margin-bottom: 80px;
  .flex-center;
  flex-direction: column;
  > .title{
    font-size: 28px;
    color: @green;
    margin-bottom: 54px;
  }
  > .img{
    width: 628px;
    height: auto;
  }
}
.person-list-wrap{
  .flex;
  .flex-wrap;
  .person-item{
    width: 350px;
    padding-bottom: 22px;
    margin-bottom: 28px;
    border-bottom: 1px solid @border;
    max-width: 366px;
    .flex;
    .align-center;
    flex-direction: column;
    .avatar{
      width: 160px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 22px;
    }
    .msg-box{
      .name-wrap{
        display: flex;
        .align-end;
        margin-bottom: 16px;
        .name{
          font-size: 18px;
          color: @black;
          margin-right: 14px;
        }
        .desc{
          font-size: 14px;
          color: @gray;
        }
      }
      .desc{
        max-width: 350px;
        font-size: 14px;
        color: @light-black;
        line-height: 1.7;
      }
    }
    // &:last-child:nth-child(3n - 1) {
    //     margin-right: calc(366px + 41px / 3);
    // }
    &:not(:nth-child(3n)) {
      margin-right: calc(16% / 3);
    }
  }
}
.contact-wrap {
  .flex;
  padding: 36px 20px;
  background-color: #fafafa;
	border: solid 1px #ededed;
  .l-msg{
    flex: 1;
    .title{
      font-size: 24px;
      color: @black;
    }
    .msg-line{
      padding-top: 16px;
      .line-item{
        .align-center;
        margin-bottom: 23px;
        .flex;
        .icon {
          margin-right: 10px;
        }
        .text{
          font-size: 16px;
          color: @black;
        }
      }
    }
    .qr-code{
      .code{
        width: 164px;
        margin-bottom: 14px;
      }
      > .text{
        font-size: 14px;
        color: @gray;
      }
    }
  }
  // .r-map{
  //   flex: 0 0 718px;
  //   width: 718px;
  //   height: 545px;
  // }
  .map_img_box {
    width: 718px;
    height: 545px;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.news-list {
  &-item{
    margin-bottom: 50px;
    > .img-box{
      width: 320px;
      margin-right: 40px;
      > .img{
        width: 100%;
      }
    }
    .content{
      padding-top: 10px;
      padding-right: 14px;
      padding-bottom: 32px;
      border-bottom: 32px;
      border-bottom: 1px solid @border;
      .title{
        font-size: 20px;
        color: #1a1a1a;
        margin-bottom: 20px;
      }
      .date{
        font-size: 14px;
        color: @gray;
        margin-bottom: 32px;
      }
      .desc{
        font-size: 14px;
        color: @light-black;
        line-height: 1.7;
      }
    }
    &:hover{
      .list-item-hover;
    }
  }
}

.detail-wrap{
  .title-msg-wrap{
    border-bottom: 1px solid @border;
    padding: 0 28px;
    padding-top: 32px;
    padding-bottom: 26px;
    margin-bottom: 30px;
    .title{
      font-size: 30px;
      color: @black;
      margin-bottom: 12px;
    }
    .date{
      font-size: 14px;
      color: @gray;
    }
  }
  .article-content-wrap{
    padding: 0 28px;
    padding-bottom: 40px;
  }
}
.project-list{
  .flex;
  .flex-wrap;
  > .item{
      width: 372px;
      margin-bottom: 52px;
    &:not(:nth-child(3n)){
      margin-right: calc(96px / 3);
    }
  }
  
}
.past-project-list{
  .flex;
  .flex-wrap;
  > .item{
      width: 232px;
      margin-bottom: 40px;
    .content{
      font-size: 16px;
      padding: 16px 13px;
    }
    &:not(:nth-child(3n)){
      margin-right: calc(84px / 3);
    }
  }
}
.study-list{
  .flex;
  .flex-wrap;
  > .item{
      width: 372px;
      margin-bottom: 40px;
    .content{
      padding: 21px 12px;
      .title{
        line-height: 1.6;
        margin-bottom: 10px;
      }
      .date{
        font-size: 14px;
        color: @gray;
      }
    }
    &:not(:nth-child(3n)){
      margin-right: calc(96px / 3);
    }
  }
}
.person-list,.support-list{
  .flex;
  .flex-wrap;
  > .item{
      width: 372px;
      margin-bottom: 50px;
      position: relative;
      .tag{
        position: absolute;
        left: 0;
        top: 0;
        width: 95px;
        height: 34px;
        background-color: @red;
        border-radius: 0px 0px 30px 0px;
        font-size: 14px;
        color: @white;
        .flex-center;
        z-index: 2;
      }
    .content{
      padding: 21px 12px;
      .title{
        font-size: 18px;
        margin-bottom: 10px;
      }
      .date{
        font-size: 14px;
        color: @gray;
        margin-bottom: 12px;
      }
      .desc{
        font-size: 14px;
        color: @light-black;
        line-height: 1.7;
      }
    }
    &:not(:nth-child(3n)){
      margin-right: calc(96px / 3);
    }
  }
}
.project-cover{
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
  > .img {
    width: 100%;
    position: relative;
    z-index: 1;
  }
  .title{
    position: absolute;
    bottom: 26px;
    left: 0;
    background-color: fade(@green, 90%);
    color: #fff;
    font-size: 38px;
    line-height: 1.6;
    z-index: 2;
    padding: 14px 30px;
  }
}

.project-tab-container{
  padding: 0 30px;
  padding-bottom: 30px;
}

.project-dynamic-list{
  > .item{
    margin-bottom: 30px;
    > .img{
      width: 232px;
      margin-right: 26px;
    }
    .content{
      max-width: 100%;
      height: 146px;
      border-bottom: 1px solid @border;
      .title{
        font-size: 16px;
        color: #1a1a1a;
        padding-top: 4px;
        line-height: 1.8;
        margin-bottom: 22px;
      }
      .date{
        font-size: 14px;
        color: @gray;
        margin-bottom: 50px;
      }
    }
    &:hover{
      .list-item-hover;
    }
  }
}

.project-publicmsg-table{
  .table-r{
    > .item {
      &:nth-child(1) {
        flex: 0 0 196px;
        width: 196px;
      }
      &:nth-child(2) {
        flex: 0 0 170px;
        width: 170px;
      }
      &:nth-child(3) {
        flex: 0 0 300px;
        width: 300px;
      }
      &:nth-child(4) {
        flex: 1;
        text-align: right;
      }
    }
  }
}
.publicmsg-table{
  .table-r{
    margin-bottom: 10px;
    > .item {
      &:nth-child(1) {
        flex: 0 0 270px;
        width: 270px;
      }
      &:nth-child(2) {
        flex: 0 0 270px;
        width: 270px;
      }
      &:nth-child(3) {
        flex: 0 0 370px;
        width: 370px;
      }
      &:nth-child(4) {
        flex: 1;
      }
    }
  }
}
.report-list{
  &-item{
    width: 202px;
    display: inline-block;
    margin-bottom: 46px;
    > .img-box{
      box-shadow: 0 3px 16px 0 rgba(2, 20, 40, 0.18);
      width: 202px;
      margin-bottom: 16px;
    }
    .title{
      font-size: 14px;
      color: @black;
      line-height: 1.7;
    }
    &:not(:nth-child(5n)){
      margin-right: calc(190px / 5);
    } 
  }
}
.grid-5-5{
  .flex;
  .col-5{
    font-size: 14px;
    color: @black;
    flex: 1;
    > .title{
      margin-bottom: 18px;
      font-size: 20px;
      color: @green;
    }
    .desc{
      line-height: 1.8;
    }
    .line{
      margin-bottom: 10px;
    }
  }
}
.donate-way-wrap{
  margin-top: 50px;
  > .title{
    text-align: center;
    font-size: 32px;
    color: @black;
    margin-bottom: 35px;
  }
  .donate-way-wrap-grid{
    .grid-5-5{
      .col-5:first-child{
        margin-right: 20px;
      }
    }
    > .tips{
      margin-top: 36px;
      font-size: 12px;
      color: @gray;
      .title{
        margin-bottom: 4px;
      }
      .desc{
        line-height: 1.8;
      }
    }
  }
}

.p-img-list{
  display: flex;
  flex-wrap: wrap;
  > .item{
    width: 30%;
    margin-bottom: 30px;
    > img{
      width: 100%;
      height: auto;
    }
    &:not(:nth-child(3n)) {
      margin-right: calc(15% / 3);
    }
  }
}