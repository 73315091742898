@import "./var";
.container{
  width: 1180px;
  margin: 0 auto;
}
.page-article-detail{
  .article-title{
    font-size: 28px;
    margin-bottom: 40px;
    color: @black;
    text-align: center;
  }
}
.text-pre-wrap{
  white-space: pre-wrap;
}
.setBg(@url,@w: 100%,@h: 100%,@position: left top) {
  background-image: url("@{image_url}@{url}");
  background-position: @position;
  background-size: 100% 100%;
  width: @w;
  height: @h;
  background-repeat: no-repeat;
}

.text-cut {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.text-cut-2 {
	overflow : hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.text-cut-3 {
	overflow : hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.flex {
	display: flex;
}

.basis-xs {
	flex-basis: 20%;
}

.basis-sm {
	flex-basis: 40%;
}

.basis-df {
	flex-basis: 50%;
}

.basis-lg {
	flex-basis: 60%;
}

.basis-xl {
	flex-basis: 80%;
}

.flex-sub {
	flex: 1;
}

.flex-twice {
	flex: 2;
}

.flex-treble {
	flex: 3;
}

.flex-direction {
	flex-direction: column;
}

.flex-wrap {
	flex-wrap: wrap;
}

.align-start {
	align-items: flex-start;
}

.align-end {
	align-items: flex-end;
}

.align-center {
	align-items: center;
}

.align-stretch {
	align-items: stretch;
}

.self-start {
	align-self: flex-start;
}

.self-center {
	align-self: flex-center;
}

.self-end {
	align-self: flex-end;
}

.self-stretch {
	align-self: stretch;
}

.align-stretch {
	align-items: stretch;
}

.justify-start {
	justify-content: flex-start;
}

.justify-end {
	justify-content: flex-end;
}

.justify-center {
	justify-content: center;
}

.justify-between {
	justify-content: space-between;
}

.justify-around {
	justify-content: space-around;
}
.flex-center{
  .flex;
  .align-center;
  .justify-center;
}


.panel-title{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  .main-title{
    font-size: 32px;
    color: @green;
    margin: 0 auto;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      top: -30px;
      right: -30px;
      width: 41px;
      height: 38px;
      background-image: url('../../../../img/sun.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .sec-title{
    font-size: 18px;
    color: @gray;
    margin-top: 10px;
    // display: inline-block;
    position: relative;
    &:before{
      content: '';
      width: 200px;
      height: 1px;
      background-color: #dbdbdb;
      position: absolute;
      top: 50%;
      left: -220px;
    }
    &:after{
      content: '';
      width: 200px;
      height: 1px;
      background-color: #dbdbdb;
      position: absolute;
      top: 50%;
      right: -220px;
    }
  }
}

.panel-nav{
  margin-top: -65px;
  margin-bottom: 80px;
  overflow: hidden;
  z-index: 3;
  position: relative;
  width: 100%;
  display: flex;
  background-color: #ffffff;
	box-shadow: 0px 2px 12px 0px 
		rgba(9, 9, 9, 0.12);
  border-radius: 6px;
  .nav-item{
    .flex-center;
    flex: 1;
    height: 100px;
    color: @black;
    font-size: 20px;
    &.active,&:hover{
      color: #fff;
      background-color: @green;
    }
  }
}

.banner-top{
  width: 100%;
  // height: 456px;
  position: relative;
  .flex-center;
  .bg{
    width: 100%;
    height: 100%;
    // position: absolute;
    // left: 0;
    // top: 0;
    // object-fit: cover;
  }
  .center-wrap{
    position: absolute;
    height: 244px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    > .img{
      height: 100%;
      width: auto;
      position: relative;
      z-index: 1;
    }
    .text{
      color: @red;
      font-weight: bold;
      font-size: 42px;
      z-index: 2;
      position: absolute;
      left: 47%;
      transform: translateX(-50%);
      top: 110px;
    }
  }
  &:after{
    content: '';
    display: inline-block;
    position: absolute;
    background-image: url('../../../../img/pc/banner-mask.png');
    background-size: 100% 100%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}

.banner-top-has-bottom{
  margin-top: 76px;
}

.green-border-nav{
  width: 100%;
  .flex;
  .justify-center;
  margin-bottom: 60px;
  > .nav-item{
    cursor: pointer;
    .flex-center;
    width: 200px;
    height: 50px;
    background-color: #fff;
    color: @green;
    border-radius: 6px;
    border: solid 1px @green;
    font-size: 18px;
    margin-right: 90px;
    &:last-child{
      margin-right: 0;
    }
    &.active,&:hover{
      color: #fff;
      background-color: @green;
    }
  }
}

.l-img-r-content{
  .flex;
  .justify-between;
  > .img{
    flex-shrink: 0;
  }
  .content{
    flex: 1;
  }
}

.bread-crumbs {
  .flex;
  padding: 22px 0;
  > .item {
    margin-right: 20px;
    position: relative;
    font-size: 14px;
    color: @gray;
    &:after{
      content: '>';
      display: inline-block;
      position: absolute;
      top: 50%;
      right: -14px;
      transform: translateY(-50%);
    }
    &:hover{
      color: @green;
    }
    &:last-child{
      margin-right: 0;
      &:after{
        display: none;
      }
    }
    &.title{
      max-width: 250px;
      .text-cut;
      &:hover{
        color: @gray;
      }
    }
  }
}

.grid-8-3{
  .flex;
  .justify-between;
  .col-8 {
    flex: 1;
    height: 100%;
    margin-right: 40px;
    background-color: #ffffff;
    box-shadow: 0px 2px 12px 0px 
      rgba(9, 9, 9, 0.12);
      &.mr0{
        margin-right: 0;
      }
  }
  .col-3{
    height: 100%;
    flex-shrink: 0;
    width: 328px;
    background-color: #ffffff;
	  box-shadow: 0px 2px 12px 0px 
		rgba(9, 9, 9, 0.12);
  }
}
.sidebar{
  overflow: hidden;
  &-title{
    padding: 18px 0;
    border-left: 7px solid #fdd000;
    position: relative;
    &:after{
      content: '';
      display: inline-block;
      .setBg('sidebar-title.png', 328px, 64px);
      position: absolute;
      left: 0;
      top: 0;
    }
    .text{
      position: relative;
      z-index: 2;
      display: inline-block;
      color: #fff;
      font-size: 20px;
      margin-left: 10px;
    }
  }
  &-list{
    padding: 0 20px;
    &-item{
      margin-top: 30px;
      > .img{
        width: 136px;
        margin-right: 18px;
      }
      .content{
        .title{
          font-size: 16px;
          color: #1a1a1a;
          line-height: 1.5;
          margin-bottom: 10px;
        }
        .date{
          color: @gray;
          font-size: 14px;
        }
      }
      &:hover{
        .list-item-hover;       
      }
      &:last-child{
        margin-bottom: 30px;
      }
    }
  }
}

.list-item-hover{
  box-shadow: 0px 2px 12px 0px 
  rgba(9, 9, 9, 0.12);
    .content{
      border-bottom: none;
      .title{
        color: @green;
      }
    }
}

.base-v-list {
  .flex;
  .flex-wrap;
  > .item {
    background-color: #ffffff;
    box-shadow: 0px 2px 12px 0px 
      rgba(9, 9, 9, 0.12); 
    > .img {
      width: 100%;
      display: block;
    }
    .content{
      width: 100%;
      font-size: 18px;
      color: #1a1a1a;
      padding: 23px  6px;
      text-align: left;
    }
  }
  &.c {
    > .item {
      .content{
        text-align: center;
      }
    }
  }
}

.hover-img {
  > .img-box {
    overflow: hidden;
    .img{
      transition: all .6s;
    }
  }
  &:hover{
    > .img-box {
      overflow: hidden;
      > .img {
        transform: scale(1.2);
      }
    }
  }
}

.g-tab-list{
  .flex;
  margin-bottom: 38px;
  padding:  0 30px;
  border-bottom: 1px solid @border; 
  .tab-item{
    color: @light-black;
    font-size: 20px;
    transition: all .3s;
    position: relative;
    line-height: 70px;
    &:after{
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0%;
      height: 3px;
      background-color: @green;
    }
    &.active,&:hover{
      color: @green;
      &:after{
        width: 100%;
      }
    }
    & + .tab-item {
      margin-left: 87px;
    }
  }
}
.pagination-box{
  width: 100%;
  justify-content: center;
  font-size: 16px;
  margin-top: 60px;
  margin-bottom: 60px;
  .prev{
    margin-right: 10px;
  }
  .next{
    margin-left: 24px;
    margin-right: 30px;
  }
  .n {
    width: 32px;
    height: 32px;
    margin-left: 20px;
    color: @black;
    .flex-center;
    border-radius: 3px;
    &.active,&:hover{
      background-color: @green;
      color: #fff;
    }
  }
}
.search-wrap{
  height: 36px;
  margin-bottom: 44px;
  form {
    width: 580px;
    position: relative;
    > input{
      padding: 12px;
      height: 100%;
      width: 100%;
      background-color: #fafafa;
      border: solid 1px #ededed;
      outline: none;
      font-size: 14px;
    }
    > button{
      cursor: pointer;
      width: 76px;
      height: 100%;
      background-color: @green;
      .flex-center;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .to-donate{
    margin-left: 20px;
    font-size: 14px;
    .flex-center;
    color: #fff;
    width: 126px;
    background-color: @green;
    border: solid 1px @green;
    height: 100%;
  }
}

.base-table{
  .table-r{
    font-size: 16px;
    color: @black;
    margin-bottom: 10px;
    > .item {
      .text-cut;
    }
    &.line{
      margin-bottom: 0;
      font-size: 14px;
      color: @light-black;
      padding: 16px 0;
      border-bottom: 1px solid @border;
    }
  }
}
.base-grid{
  background-color: #ffffff;
	box-shadow: 0px 2px 20px 0px 
    rgba(9, 9, 9, 0.24);
  width: 100%;
  padding: 40px;
}
.no-more-data{
  width: 100%;
  color: @gray;
  margin-top: .5rem;
  margin-bottom: .5rem;
  text-align: center;
}